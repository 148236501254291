<template>
  <v-dialog v-model="dialog" style="z-index:1;" @keydown.esc="cancel" width="1000">
<!-- :max-width="options.width" -->
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <div class="d-flex flex-nowrap" style="height:400px !important;">
        <v-simple-table height="400px" fixed-header style="overflow: auto; width: 100%;">
          <thead>
            <tr>
              <th style="position: sticky !important; top: 0 !important; z-index: 1"><v-text-field placeholder="RECETA" dense v-model="searchTerm" :append-icon="searchTerm ? 'mdi-close' : ''" @click:append="searchTerm=''" hide-details=""></v-text-field></th>
              <th style="position: sticky !important; top: 0 !important; z-index: 1">ARTÍCULO PRINCIPAL</th>
              <th style="position: sticky !important; top: 0 !important; z-index: 1">DESCRIPCIÓN</th>
              <th style="position: sticky !important; top: 0 !important; z-index: 1">PRECIO</th>
            </tr>
          </thead>
          <tbody>
            <tr
            style="cursor: pointer;"
            v-for="receta in recList.filter(rec => (rec.nombre.toLowerCase().includes(searchTerm.toLowerCase()) || articulos.filter(art => art.idArticulo == escandallos.filter(e => e.idEscandallo == rec.escandallos[0].idEscandallo).map( a=> a.idArticulo)[0]).map(a => a.nombre)[0].toLowerCase().includes(searchTerm.toLowerCase())))"
            @click.stop="agree(receta.idReceta)"
            >
              <td>
                {{ receta.nombre }}
              </td>
              <td style="white-space: nowrap !important;">
                {{ articulos.filter(art => art.idArticulo == escandallos.filter(a => a.idEscandallo == receta.escandallos[0].idEscandallo).map(a=>a.idArticulo)).map(a => a.nombre)[0] }}, {{ escandallos.filter(a => a.idEscandallo == receta.escandallos[0].idEscandallo).map(a=>a.nombre)[0] }} (x{{ receta.escandallos[0].uds }})
              </td>
              <td>
                {{ receta.nota }}
              </td>
              <td style="white-space: nowrap !important;text-align: right;">
                {{ parseFloat(pr(receta.escandallos)).toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €
              </td>
            </tr>                
          </tbody>
        </v-simple-table>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props:{
    categorias: Array,
    escandallos: Array,
    articulos: Array,
    recetas: Array
  },
  data: () => ({
    rules: [v => !!v || "Este campo es obligatorio", v => !isNaN(v) || "Introduce un número"],
    searchTerm: '',
    recList: [],
    valid: true,
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 1700,
      zIndex: 200
    }
  }),
  methods: {
    pr(i){
      var total = 0
      i.forEach(esc => {
        try{
          total += eval(this.escandallos.filter(e => e.idEscandallo == esc.idEscandallo).map(a => a.formula)[0].replaceAll(',','.').replaceAll('p', this.articulos.filter(art => art.idArticulo == this.escandallos.filter(e => e.idEscandallo == esc.idEscandallo).map(a => a.idArticulo)[0]).map(a => a.precio))) * esc.uds
        }
        catch(e){
          total += 0
        }
      });
      return total
    },
    open(id) {
      this.recList = this.recetas.filter(r => r.categorias.includes(id))
      this.dialog = true
      this.title = "Añadir receta"
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree(r) {
      this.resolve(r)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>